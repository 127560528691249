import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Helmet } from "react-helmet"
import {
  BadgeCheckIcon,
  AcademicCapIcon

} from '@heroicons/react/outline'
import ContactForm from "../components/contactFormClass"
import Footer from "../components/footer"
import { ChevronDownIcon } from '@heroicons/react/solid'
import logo from "../images/SG-logo.svg"
import topBg from "../images/gdynia-n3miasto.jpg"
import doswiadczenie from "../images/doswiadczenie.png"
import przejrzysteWarunki from "../images/przejrzyste-warunki.png"
import znajomoscRynku from "../images/znajomosc-rynku.png"
import korzystneWarunki from "../images/korzystne-warunki.png"
import bezpieczenstwo from "../images/bezpieczenstwo.png"
import partnerstwo from "../images/partnerstwo.png"
import referencje1 from "../images/referencje-1.jpg"
import lighthouse from "../images/lighthouse.jpg"
import orlowo from "../images/nieruchomosci-orlowo.jpg"
import kGerstmann from "../images/Krzysztof_websize.jpg"
import sSmolinski from "../images/Sebastian_websize.jpg"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="bg-slate-200">
      <Helmet
      title="Smoliński & Gerstmann Nieruchomości"

      >
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon.png"
        />
        <body className="scroll-smooth" />
        
      </Helmet>
      <header class="fixed z-20 w-full shadow-xl">
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={logo}
                  alt=""
                />
              </a>
            </div>
            

              <a href="#co-nas-wyroznia" className="hidden md:inline text-base font-medium text-gray-500 hover:text-gray-900">
                Co nas wyróżnia
              </a>
              <a href="#dlaczego-warto" className="hidden md:inline text-base font-medium text-gray-500 hover:text-gray-900">
                Dlaczego warto
              </a>
              <a href="#o-nas" className="hidden md:inline text-base font-medium text-gray-500 hover:text-gray-900">
                O nas
              </a>
            <div className="flex items-center justify-end md:flex-1 lg:w-0">
              
              <a
                href="#top"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-n3navy bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white"
              >
                Kontakt
              </a>
            </div>
          </div>

        </Popover>
      </header>

      <main>
        {/* Hero section */}
        <div id="top" className="relative pt-20">
          <div className="absolute inset-x-0 bottom-0 max-h-full bg-white" />
          <div className="max-w-full mx-auto sm:px-6 lg:px-0">
            <div className="relative shadow-2xl sm:rounded-tr-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover saturate-0"
                  src={topBg}
                  alt="Gdynia Biuro Nieruchomości"
                />
                <div className="absolute inset-0 bg-gray-900 opacity-80 mix-blend-multiply" />
              </div>
              <div className="relative max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 grid s:grid-cols-1 md:grid-cols-2">
                <div className="flex flex-col justify-center mb-20">
                  <h2 className="text-left text-sm font-semibold uppercase text-n3gold tracking-wide">Z nami skutecznie sprzedasz swoją nieruchomość!</h2>
                  <h1 className="text-left text-3xl font-light uppercase tracking-tight sm:text-3xl lg:text-5xl">
                    <span className="block text-white mb-2">Ty podejmij decyzję</span>
                    <span className=" bg-n3navy  text-white inline-block px-2 py-1">my zajmiemy się resztą!</span>
                  </h1>
                  
                  <p className="mt-6  text-left text-xl text-gray-200 ">
                    Nasze usługi to gwarancja Twojego bezpieczeństwa i współpracy z najlepszymi doradcami na trójmiejskim rynku nieruchomości.
                  </p>
                  <div className="mt-10  sm:flex sm:justify-start">
                    <div className="space-y-4 sm:space-y-0 sm:inline-grid sm:grid-cols-2 sm:gap-5">
                      <a
                        href="#co-nas-wyroznia"
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-n3navy bg-white hover:bg-indigo-50 sm:px-8"
                      >
                        Dowiedz się więcej
                      </a>
                      <a
                        href="#dlaczego-warto"
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-n3gold  hover:bg-opacity-90 sm:px-8"
                      >
                        Sprawdź nas
                      </a>
                      </div>
                    </div>
                  </div>    
                <div className="relative px-10 mb-20">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative">
                        <ContactForm/>
                </div>        
                </div>
                </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div id="co-nas-wyroznia" className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
             Co nas wyróżnia
            </p>
            <div className="mt-6 grid gap-8 md:grid-cols-3 lg:grid-cols-6">
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={doswiadczenie} alt="Tuple" />
                <h3 className="text-gray-600">Doświadczenie</h3>
              </div>
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={przejrzysteWarunki} alt="Tuple" />
                <h3 className="text-gray-600">Przejrzyste warunki</h3>
              </div>
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={znajomoscRynku} alt="Tuple" />
                <h3 className="text-gray-600">Znajomość rynku</h3>
              </div>
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={korzystneWarunki} alt="Tuple" />
                <h3 className="text-gray-600">Korzystne warunki</h3>
              </div>
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={bezpieczenstwo} alt="Tuple" />
                <h3 className="text-gray-600">Bezpieczeństwo</h3>
              </div>
              <div className="col-span-1 flex flex-col text-center content-center items-center justify-center">
                <img className="max-h-24 w-auto aspect-square filter saturate-0" src={partnerstwo} alt="Tuple" />
                <h3 className="text-gray-600">Partnerstwo</h3>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-n3navy">
                      <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Nasze doświadczenie do Twojej dyspozycji
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                     Dzięki wieloletniemu doświadczeniu i znajomości lokalnego rynku nieruchomości udzielamy naszym klientom fachowych porad niezbędnych dla zrealizowania najbardziej korzystnych dla nich transakcji. Zdobyte doświadczenie w połączeniu z ogromną energią sprawia, że pracę pośrednika wykonujemy z coraz większym zapałem i zaangażowaniem.
                    </p>
                    <div className="mt-6">
                      <a
                        href="#"
                        className="inline-flex bg-n3navy bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                      >
                        Sprawdź nas
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6 bg-gradient-to-r from-gray-200 to-white p-10 -ml-10 border-l-2 border-t-0 border-gray-400 shadow-xl">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500 italic">
                        &ldquo;Szybko, sprawnie, profesjonalnie. Duże zaangażowanie i cierpliwość Krzysztofa, wszystko wytłumaczył i prowadził krok po kroku całą transakcję. Z czystym sumieniem polecam.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3 -ml-1">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={referencje1}
                            alt=""
                          />
                        </div>
                        <div className="text-base text-[14px] tracking-tighter font-semibold text-gray-700 ">
                          Pan Jerzy, sprzedał za naszym pośrednictwem apartament w Gdyni
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={orlowo}
                    alt="Nieruchomości Orłowo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="dlaczego-warto" className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-n3navy">
                      <BadgeCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Dlaczego warto:
                    </h2>
                    <ul className="mt-4 text-lg text-gray-500 list-disc pl-5 list-blue marker:text-n3gold">
                      <li>Licencja państwowa nr 16007</li>
                      <li>Ubezpieczenie OC pośrednika nieruchomości</li>
                      <li>Doświadczenie i wiedza potwierdzone działalnością na rynku od 2005 roku</li>
                      <li>Rzetelne i bardzo indywidualne podejście do każdego Klienta</li>
                      <li>Gwarancja bezpieczeństwa</li>
                      <li>Regularne polecenia naszej firmy przez naszych dotychczasowych Klientów</li>
                      <li>Osługa sprzedaży i zakupu przez współwłaścicieli biura</li>
                      <li>Profesjonalna sesja fotograficzna</li>
                    </ul>
                    <div className="mt-8">
                      <a
                        href="#"
                        className="inline-flex bg-n3navy bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                      >
                        Zacznij już teraz
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={lighthouse}
                    alt="Nieruchomości Gdynia"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* team   */}
        <div id="o-nas" className="relative bg-gray-900">
          <div className="images grid md:grid-cols-1 xl:grid-cols-2">
            <div className="relative"><img
                  className="h-full w-full object-cover opacity-25  inset-0"
                  src={sSmolinski}
                  alt="Sebastian Smolinski"
                />
                <div
                  aria-hidden="true"
                  className="absolute file:inset-0 w-full inset-x-0 top-0 bg-gradient-to-t from-gray-900 via-transparent xl:inset-y-0 xl:left-0 h-full flex justify-end flex-col text-white px-20 pb-10"
                >
                <h3 className="text-3xl font-bold tracking-tight mb-5">Sebastian Smoliński</h3>
                <p className="leading-5 text-sm font-light text-justify">Sebastian prywatnie jest pełnym wyrozumiałości tatą Marysi i Stasia. Ogromnym wielbicielem przyrody, długich i weekendowych wypraw w nieznane, najlepiej pieszych lub rowerowych, samotnie lub w gronie najbliższych przyjaciół. Związany z trójmiejską branżą nieruchomości od 2005 roku. Od dziecka zakochany w mieście z morza i marzeń, obecnie zamieszkuje północny jego zakątek.</p>
                </div></div>
                <div className="relative">
                  <img
                  className="h-full w-full object-cover opacity-25 inset-0"
                  src={kGerstmann}
                  alt="Krzysztof Gerstmann"
                />
                <div
                  aria-hidden="true"
                  className="absolute file:inset-0 w-full inset-x-0 top-0 bg-gradient-to-t from-gray-900 via-transparent inset-y-0 xl:left-0 h-full flex justify-end flex-col text-white px-20 pb-10 text-right"
                >
                <h3 className="text-3xl font-bold tracking-tight mb-5 ">Krzysztof Gerstmann</h3>
                <p className="leading-5 text-sm font-light text-justify">Krzysztof jest absolwentem Uniwersytetu Gdańskiego (Zarządzanie i Marketing) oraz Wyższej Szkoły Gospodarowania Nieruchomościami w Warszawie (Wycena Nieruchomości). W codziennej pracy stara się pomagać naszym Klientom w ich podróżach po świecie nieruchomości – aby były łatwiejsze, przyjemniejsze, szybsze i bezpieczniejsze.</p>
                </div>
                </div>
            </div>
        </div>  
          
          

        {/* CTA Section */}
        <div className="bg-gray-900">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-bold tracking-tight text-gray-200 sm:text-4xl">
              <span className="block">Pytania, wątpliwości?</span>
              <span className="-mb-1 pb-1 block text-n3gold">
                Serdecznie zapraszamy do kontaktu
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <a
                href="#co-nas-wyroznia"
                className="flex items-center justify-center bg-n3gold bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Dowiedz się więcej
              </a>
              <a
                href="#top"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100"
              >
                Napisz do nas
              </a>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}
