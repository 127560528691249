import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage, Label } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa"),
  privacy: Yup.bool().oneOf([true], "Wymagana zgoda."),
})

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        // window.fbq('track', 'Lead');
        // window.dataLayer.push({
        //   event: "conversion",
        // })
      })
      .catch(error => this.setState({ error: error.message }))
  }


  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            privacy: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <>
            <h3 className="text-white py-1 text-left text-xl font-bold mb-7 uppercase tracking-tight -mx-10 px-10 bg-gradient-to-r from-n3navy to-slate-500">Zostaw namiary, oddzwonimy!</h3>
            <Form className="space-y-6">
                <div className="">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Imię i nazwisko
                  <Field className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-n3navy focus:border-n3navy sm:text-sm" type="text" name="name" />
                  <ErrorMessage
                      className="error-msg text-xs text-red-700"
                      name="name"
                      component="span"
                    />
                    </label>
                </div>
                <div className="">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-mail
                  <Field className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-n3navy focus:border-n3navy sm:text-sm" type="text" name="email" />
                  <ErrorMessage
                      className="error-msg text-xs text-red-700"
                      name="email"
                      component="span"
                    />
                    </label>
                </div>
                <div className="">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Numer telefonu
                  <Field className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-n3navy focus:border-n3navy sm:text-sm" type="text" name="phone" />
                  <ErrorMessage
                      className="error-msg text-xs text-red-700"
                      name="phone"
                      component="span"
                    />
                    </label>
                </div>
                <button
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-n3navy hover:bg-n3navy hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-n3navy"
                type="submit"
                disabled={isSubmitting}
              >
                Wyślij
              </button>
                <div className="privacy-policies">
                <div>
                  <label
                    className="pl-6 block text-[10px] text-gray-600 relative"
                    htmlFor="privacy"
                  >
                    Korzystając z formularza, zgadzasz się na przechowywanie i przetwarzanie Twoich danych osobowych które wykorzystamy wyłącznie w celu kontaktu zwrotnego. Informacje o tym jak dbamy o Twoje dane znajdziesz w naszej <a href="https://n3miasto.pl/polityka-prywatnosci/" target="blank" className="underline"> Polityce prywatności</a>.
                  <Field
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    className="absolute left-0 top-1"
                  />
                    <ErrorMessage
                      className="error-msg text-xs text-red-700"
                      name="privacy"
                      component="span"
                    />
                  </label>
                </div>
              </div>
              
            </Form>
            </>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Dziękujemy!</h3>
          <p className="text-xl mb-12">Wiadomość została wysłana.</p>
        </form>
      )
    }
  }
}
